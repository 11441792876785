import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="benchmark"
export default class extends Controller {
  static targets = ['benchmarkButton', 'benchmarkPanel', 'loadingIndicator']
  static values = {
      restaurantId: String,
      type: String,
      year: String,
      format: String,
      decimals: Number
  }

  connect() {
      this.visibleStates = {}
      this.cachedData = {}
  }

  getBenchmarkClassName(type, year) {
      return `benchmark-${type}-${year}`
  }

  async toggleBenchmark(event) {
      event.preventDefault()
      const button = event.currentTarget
      const type = button.dataset.benchmarkTypeValue
      const year = this.yearValue
      const stateKey = `${year}-${type}`

      this.showLoadingIndicator()

      if (!this.cachedData[stateKey]) {
          try {
              let baseUrl = '/restaurant_objectives/benchmark'
              if (document.body.dataset.locale) {
                  baseUrl = `/${document.body.dataset.locale}${baseUrl}`
              }
              const response = await fetch(baseUrl, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'X-Requested-With': 'XMLHttpRequest',
                      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                  },
                  body: JSON.stringify({
                      type: type,
                      restaurant_id: button.dataset.benchmarkRestaurantIdValue,
                      year: year,
                      day_filter: document.querySelector('input[name="day_filter"]:checked')?.value,
                  }),
                  credentials: 'same-origin',
              })

              if (!response.ok) throw new Error('Network response was not ok')
              this.cachedData[stateKey] = await response.json()
          } catch (error) {
              console.error('Error:', error)
              return
          }
      }

      this.hideLoadingIndicator()

      if (this.visibleStates[stateKey]) {
          this.hideBenchmark(type, year, button)
          this.visibleStates[stateKey] = false
      } else {
          this.showBenchmark(this.cachedData[stateKey], type, year, button)
          this.visibleStates[stateKey] = true
      }
  }

  showLoadingIndicator() {
    this.loadingIndicatorTarget.classList.remove('d-none')
  }

  hideLoadingIndicator() {
      this.loadingIndicatorTarget.classList.add('d-none')
  }

  showBenchmark(data, type, year, button) {
      const parentRow = this.element
      const nextSibling = parentRow.nextElementSibling
      const uniqueClass = this.getBenchmarkClassName(type, year)
      const benchmarkRows = this.generateBenchmarkRows(data, uniqueClass)

      benchmarkRows.forEach(row => {
          parentRow.parentElement.insertBefore(row, nextSibling)
      })

      button.innerHTML = '<i class="fa-regular fa-eye-slash"></i>'
  }

  hideBenchmark(type, year, button) {
      const uniqueClass = this.getBenchmarkClassName(type, year)
      const existingBenchmarks = document.querySelectorAll(`.${uniqueClass}`)
      existingBenchmarks.forEach(row => row.remove())
      button.innerHTML = '<i class="fa-regular fa-eye"></i>'
  }

  generateBenchmarkRows(data, uniqueClass) {
      const rows = []
      const button = this.benchmarkButtonTarget
      const format = button.dataset.benchmarkFormatValue
      const decimals = button.dataset.benchmarkDecimalsValue || 2
      console.log("in generateBenchmarkRows - décimal value : ", decimals)

      const formatValue = (value) => {
        const numericValue = parseFloat(value)
        if (format === 'euro') {
            return this.formatCurrency(numericValue, decimals)
        }
        return `${numericValue.toFixed(decimals)}%`
    }
    console.log("in generateBenchmarkRows - format value : ", format)
      const filteredRestaurants = data.restaurants.filter(r => r.id !== parseInt(this.restaurantIdValue))

      filteredRestaurants.forEach(restaurant => {
          const row = document.createElement('tr')
          row.classList.add(uniqueClass)
          row.innerHTML = `
              <td class="p-0 ps-4 fst-italic" style="font-size: 0.75rem;">↳ ${restaurant.name}</td>
              ${restaurant.values.map(value => `
                  <td class="p-0 text-center fst-italic" style="font-size: 0.75rem;">${formatValue(value)}</td>
              `).join('')}
              <td class="p-0 text-center fst-italic" style="font-size: 0.75rem;">${
                  formatValue(restaurant.values.reduce((a, b) => a + parseFloat(b), 0) / restaurant.values.length)
              }</td>
          `
          rows.push(row)
      })

      const avgRow = document.createElement('tr')
      avgRow.classList.add(uniqueClass)
      avgRow.innerHTML = `
          <td class="p-0 ps-4 fst-italic" style="font-size: 0.75rem;">↳ <strong>${i18n.t('i18njs.Average for group')}</strong></td>
          ${data.monthly_averages.map(avg => `
              <td class="p-0 text-center fst-italic" style="font-size: 0.75rem;"><strong>${formatValue(avg)}</strong></td>
          `).join('')}
          <td class="p-0 text-center fst-italic" style="font-size: 0.75rem;"><strong>${
              formatValue(data.monthly_averages.reduce((a, b) => a + parseFloat(b), 0) / data.monthly_averages.length)
          }</strong></td>
      `
      rows.push(avgRow)

      return rows
  }

  formatCurrency(value, decimals) {
    console.log("in formatCurrency - décimal value : ", decimals)
    const withDecimals = value.toFixed(decimals)
    const [intPart, decPart] = withDecimals.toString().split('.')
    const formattedInt = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    return decPart ? `${formattedInt},${decPart} €` : `${formattedInt} €`
}
}
