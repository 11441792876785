import { Controller } from "@hotwired/stimulus";
import Pickr from "@simonwep/pickr";

export default class extends Controller {
  static targets = ["output"]; // Définit les cibles pour l'input texte synchronisé

  connect() {
    // Initialisation de Pickr
    this.pickr = Pickr.create({
      el: this.element.querySelector("button"),
      theme: 'classic', // Thème : 'classic', 'monolith', 'nano'
      default: this.outputTarget.value || '#000000',
      swatches: [
        '#F44336', '#E91E63', '#9C27B0', '#673AB7',
        '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4',
        '#009688', '#4CAF50', '#8BC34A', '#CDDC39',
        '#FFEB3B', '#FFC107', '#FF9800', '#FF5722'
      ],
      components: {
        preview: true,
        opacity: true,
        hue: true,
        interaction: {
          save: true
        }
      }
    });

    // Gestion de la synchronisation entre Pickr, l'input texte, le bouton et l'icône
    this.pickr.on('change', (color) => {
      this.updateColor(color);
    });

    // Appliquer immédiatement la couleur lors de la sélection d'un swatch
    this.pickr.on('swatchselect', (color) => {
      this.updateColor(color);
    });

    // Ferme le picker lorsqu'on clique sur "Save"
    this.pickr.on('save', (color) => {
      this.updateColor(color);
      this.pickr.hide();
    });

    // Ajout d'un gestionnaire pour l'input texte
    this.outputTarget.addEventListener('input', (event) => {
      const hexColor = event.target.value;
      if (this.isValidHex(hexColor)) {
        this.pickr.setColor(hexColor); // Met à jour Pickr avec la nouvelle couleur
        this.updateButtonAndIcon(hexColor); // Change la couleur du bouton et de l'icône
      }
    });
  }

  // Vérifie si la valeur entrée est un code hexadécimal valide
  isValidHex(color) {
    return /^#([0-9A-F]{3}){1,2}$/i.test(color);
  }

  // Met à jour les éléments visuels (bouton et icône)
  updateColor(color) {
    const hexColor = color.toHEXA().toString();
    this.outputTarget.value = hexColor; // Met à jour l'input texte
    this.updateButtonAndIcon(hexColor); // Change la couleur du bouton et de l'icône
  }

  updateButtonAndIcon(hexColor) {
    // Mise à jour explicite du bouton
    const button = this.element.querySelector("button");
    if (button) {
      button.style.backgroundColor = hexColor;
    }

    // Mise à jour explicite de l'icône
    const icon = this.element.querySelector(".fa-palette");
    if (icon) {
      icon.style.color = hexColor;
    }
  }
}
