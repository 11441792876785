import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="modal-ingredients"
export default class extends Controller {
    static targets = [
        'totalRowPrice',
        'totalOrderPrice',
        'francoSubmit',
        'ingredientFields',
        'zeroValuesWarning',
        'francoReachingWarning',
        'maximumValueWarning',
    ]
    indexCounter = 1000
    selectedIngredientIds = new Set()
    isUpdating = false
    debounceTimeout = null
    isSubmitting = false

    connect() {
        document.addEventListener(
            'turbo:frame-load',
            this.reapplySelections.bind(this)
        )
        this.updateTotalPrice()
        this.checkDeliveryDelays()
    }

    checkDeliveryDelays() {
        const hasDelayedItems = Array.from(
            this.ingredientFieldsTarget.querySelectorAll(
                'input[data-delivery-delay-in-time="false"]'
            )
        ).some((input) => input.dataset.deliveryDelayInTime === 'false')

        const confirmButton = document.querySelector(
            '[data-bs-target="#confirmOrderModal"]'
        )
        const confirmModal = document.getElementById('confirmOrderModal')
        const modalInstance = bootstrap.Modal.getInstance(confirmModal)

        if (modalInstance) {
            modalInstance.dispose()
        }

        if (hasDelayedItems) {
            confirmButton.classList.add('disabled')
            if (!document.getElementById('delivery-delay-error')) {
                const errorDiv = document.createElement('div')
                errorDiv.id = 'delivery-delay-error'
                errorDiv.className = 'alert alert-danger mt-2'
                errorDiv.innerHTML =
                    'Une ou plusieurs lignes dépassent les délais de commande. Veuillez les supprimer avant de soumettre la commande.'
                confirmButton.parentNode.insertBefore(
                    errorDiv,
                    confirmButton.nextSibling
                )
            }
        } else {
            confirmButton.classList.remove('disabled')
            document.getElementById('delivery-delay-error')?.remove()
            new bootstrap.Modal(confirmModal)
        }
    }

    reapplySelections() {
        this.selectedIngredientIds.forEach((id) => {
            const checkbox = document.querySelector(
                `.modal-body .form-check-input[value='${id}']`
            )
            if (checkbox) {
                checkbox.checked = true
            }
        })
    }

    toggleSelection(event) {
        const checkbox = event.currentTarget
        if (checkbox.checked) {
            this.selectedIngredientIds.add(checkbox.value)
        } else {
            this.selectedIngredientIds.delete(checkbox.value)
        }
    }

    submit() {
        // Empêcher les soumissions multiples simultanées
        if (this.isSubmitting) return
        this.isSubmitting = true

        const promises = Array.from(this.selectedIngredientIds).map(
            async (ingredientId) => {
                const supplierOrderId = this.element.dataset.supplierOrderId
                const statusSent = this.element.dataset.statusSent
                let url = `/supplier_orders/${supplierOrderId}/order_items`

                if (document.body.dataset.locale) {
                    url = `/${document.body.dataset.locale}${url}`
                }

                try {
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': document.querySelector(
                                'meta[name=csrf-token]'
                            ).content,
                        },
                        body: JSON.stringify({
                            ingredient_id: ingredientId,
                            status_sent: statusSent,
                        }),
                    })
                    const data = await response.json()
                    if (data.status === 'success') {
                        const categoryName =
                            data.order_item.ingredient
                                .ingredient_secondary_category?.name ||
                            'No category'
                        let categoryRow =
                            this.findOrCreateCategoryRow(categoryName)

                        const categoryId =
                            categoryRow.dataset.categoryId ||
                            `category-${categoryName.replace(/\s+/g, '-').toLowerCase()}`
                        categoryRow.setAttribute('data-category-id', categoryId)

                        const newRow = document.createElement('tr')
                        newRow.id = `ingredient-row-${data.order_item.ingredient.id}`
                        newRow.setAttribute('data-category-id', categoryId)

                        categoryRow.insertAdjacentElement('afterend', newRow)

                        this.buildIngredientRow(
                            newRow,
                            data.order_item,
                            statusSent
                        )

                        const correspondingDiv = document.querySelector(
                            `[data-ingredient-modal-id="ingredientModalDiv_${ingredientId}"]`
                        )
                        if (correspondingDiv) {
                            correspondingDiv.classList.add('d-none')
                        }

                        this.updateTotalPrice()
                    }
                } catch (error) {
                    console.error('Erreur:', error)
                }
            }
        )

        Promise.all(promises)
            .then(() => {
                this.selectedIngredientIds.clear()
                document
                    .querySelectorAll(
                        ".modal-body .form-check-input[type='checkbox']"
                    )
                    .forEach((checkbox) => (checkbox.checked = false))
            })
            .finally(() => {
                this.isSubmitting = false
                this.indexCounter++
            })
    }

    findOrCreateCategoryRow(categoryName) {
        let categoryRow

        // Si le nom de la catégorie est "No category"
        if (categoryName === 'No category') {
            // Vérifiez s'il existe déjà une ligne "No category"
            categoryRow = this.ingredientFieldsTarget.querySelector(
                'tr[data-category-id="category-no-category"]'
            )

            // Si aucune ligne "No category" n'existe, en créer une
            if (!categoryRow) {
                categoryRow = this.ingredientFieldsTarget.insertRow()
                categoryRow.classList.add('table-info')
                categoryRow.setAttribute(
                    'data-category-id',
                    'category-no-category'
                )
                const categoryCell = categoryRow.insertCell(0)
                categoryCell.colSpan = 10
                categoryCell.innerHTML = '<strong>No category</strong>'
            }
        } else {
            // Pour les autres catégories, vérifiez s'il existe déjà une ligne pour cette catégorie
            categoryRow = [
                ...this.ingredientFieldsTarget.querySelectorAll(
                    'tr.table-info'
                ),
            ].find((row) => row.textContent.trim() === categoryName)

            // Si la ligne n'existe pas, la créer
            if (!categoryRow) {
                categoryRow = this.ingredientFieldsTarget.insertRow()
                categoryRow.classList.add('table-info')
                categoryRow.setAttribute(
                    'data-category-id',
                    `category-${categoryName.replace(/\s+/g, '-').toLowerCase()}`
                )
                const categoryCell = categoryRow.insertCell(0)
                categoryCell.colSpan = 10
                categoryCell.innerHTML = `<strong>${categoryName}</strong>`
            }
        }

        return categoryRow
    }

    buildIngredientRow(newRow, orderItem, statusSent) {
        let cell0, cell1, cell2, cell3, cell4, cell5, cell6, cell7, cell8, cell9
        let offset = 0
        console.log(
            "this.element.dataset.acceptedBySupplier.trim() !== ''",
            this.element.dataset.acceptedBySupplier.trim() !== ''
        )
        if (
            this.element.dataset.acceptedBySupplier.trim() !== '' &&
            statusSent === 'true'
        ) {
            // Ajouter une colonne pour l'icône d'acceptation
            cell0 = newRow.insertCell(0)
            // cell0.innerHTML = `<i class="fa-solid ${orderItem.accepted_by_supplier ? 'fa-square-check text-success' : 'fa-square-xmark text-danger'} fa-2xl"></i>`;
            cell0.classList.add('text-center', 'align-middle')
            offset = 1 // Augmente l'offset si la colonne est ajoutée
        }

        // Création des autres cellules en fonction de `statusSent`
        cell1 = newRow.insertCell(0 + offset)
        cell2 = newRow.insertCell(1 + offset)
        cell3 = newRow.insertCell(2 + offset)
        cell4 = newRow.insertCell(3 + offset)
        cell5 = newRow.insertCell(4 + offset)
        if (statusSent === 'true') {
            cell9 = newRow.insertCell(5 + offset)
            cell6 = newRow.insertCell(6 + offset)
            cell7 = newRow.insertCell(7 + offset)
            cell8 = newRow.insertCell(8 + offset)
        } else {
            cell6 = newRow.insertCell(5 + offset)
            cell7 = newRow.insertCell(6 + offset)
            cell8 = newRow.insertCell(7 + offset)
        }

        if (orderItem.ingredient.photo_url) {
            cell1.innerHTML = `<i class="fa-solid fa-image text-success me-1"></i> ${orderItem.ingredient.name_short}`
            cell1.setAttribute('scope', 'row')
            cell1.setAttribute('style', 'width:40%')
            cell1.setAttribute('data-bs-toggle', 'tooltip')
            cell1.setAttribute('data-bs-placement', 'right')
            cell1.setAttribute('data-bs-html', 'true')
            cell1.classList.add('align-middle')
            cell1.setAttribute(
                'title',
                `<img src="${orderItem.ingredient.photo_url}" class="img-fluid" alt="${orderItem.ingredient.name_short}">`
            )
            new bootstrap.Tooltip(cell1)
        } else {
            cell1.innerHTML = orderItem.ingredient.name_short
            cell1.setAttribute('scope', 'row')
            cell1.setAttribute('style', 'width:40%')
            cell1.classList.add('align-middle')
        }

        cell2.innerHTML = `€${(orderItem.ingredient.price_cents / orderItem.ingredient.quantity_by_pack / 100).toFixed(2)}`
        cell2.setAttribute('scope', 'row')
        cell2.setAttribute('style', 'width:7%')
        cell2.classList.add('text-center', 'align-middle')

        cell3.innerHTML = orderItem.ingredient.quantity_by_pack
        cell3.setAttribute('scope', 'row')
        cell3.setAttribute('style', 'width:7%')
        cell3.classList.add('text-center', 'align-middle')

        cell4.innerHTML = orderItem.ingredient.unit_measure
        cell4.setAttribute('scope', 'row')
        cell4.setAttribute('style', 'width:7%')
        cell4.classList.add('text-center', 'align-middle')

        if (statusSent === 'true') {
            let quantityAddedInput = document.createElement('input')
            quantityAddedInput.type = 'number'
            quantityAddedInput.min = 0
            quantityAddedInput.value = 1
            quantityAddedInput.name = 'quantity'
            quantityAddedInput.id = 'quantity'
            quantityAddedInput.classList.add(
                'custom-read-only',
                'ingredient-quantity',
                'text-center',
                'form-control'
            )
            quantityAddedInput.dataset.id = orderItem.id
            quantityAddedInput.dataset.unitMeasure =
                orderItem.ingredient.unit_measure
            quantityAddedInput.dataset.ingredientId = orderItem.ingredient.id
            quantityAddedInput.dataset.priceCents =
                orderItem.ingredient.price_cents
            quantityAddedInput.dataset.quantityByPack =
                orderItem.ingredient.quantity_by_pack
            quantityAddedInput.dataset.addedAfterSend = 'true'
            quantityAddedInput.dataset.action =
                'change->modal-ingredients#updateQuantity'
            cell9.appendChild(quantityAddedInput)
            cell9.setAttribute('scope', 'row')
            cell9.setAttribute('style', 'width:18%')
            cell9.classList.add('text-center', 'align-middle')
        } else {
            let quantityInput = document.createElement('input')
            quantityInput.type = 'number'
            quantityInput.min = 0
            quantityInput.value = 1
            quantityInput.name = 'quantity'
            quantityInput.id = 'quantity'
            quantityInput.classList.add(
                'custom-read-only',
                'ingredient-quantity',
                'text-center',
                'form-control'
            )
            quantityInput.dataset.id = orderItem.id
            quantityInput.dataset.unitMeasure =
                orderItem.ingredient.unit_measure
            quantityInput.dataset.ingredientId = orderItem.ingredient.id
            quantityInput.dataset.priceCents = orderItem.ingredient.price_cents
            quantityInput.dataset.quantityByPack =
                orderItem.ingredient.quantity_by_pack
            quantityInput.dataset.action =
                'change->modal-ingredients#updateQuantity'
            cell5.appendChild(quantityInput)
            cell5.setAttribute('scope', 'row')
            cell5.setAttribute('style', 'width:18%')
            cell5.classList.add('text-center', 'align-middle')
        }

        const totalPieces = (1 * orderItem.ingredient.quantity_by_pack).toFixed(
            0
        )
        cell6.innerHTML = totalPieces
        cell6.setAttribute('scope', 'row')
        cell6.setAttribute('style', 'width:7%')
        cell6.classList.add('text-center', 'align-middle')
        cell6.dataset.orderedQuantityId = orderItem.ingredient.id

        const totalPrice = (
            (1 * orderItem.ingredient.price_cents) /
            100
        ).toFixed(2)
        cell7.innerHTML = `€${totalPrice}`
        cell7.setAttribute('scope', 'row')
        cell7.setAttribute('style', 'width:7%')
        cell7.classList.add('text-center', 'align-middle')
        cell7.dataset.totalPriceId = orderItem.ingredient.id
        cell7.dataset.modalIngredientsTarget = 'totalRowPrice'

        let deleteLink = document.createElement('a')
        deleteLink.innerHTML = '<i class="fa-solid fa-trash"></i>'
        deleteLink.href = '#'
        deleteLink.dataset.action = 'click->modal-ingredients#delete'
        deleteLink.dataset.orderItemId = orderItem.id
        deleteLink.dataset.ingredientId = orderItem.ingredient.id
        cell8.appendChild(deleteLink)
        cell8.setAttribute('scope', 'row')
        cell8.setAttribute('style', 'width:7%')
        cell8.classList.add('text-center', 'align-middle')
    }

    delete(event) {
        event.preventDefault()
        const itemId = event.currentTarget.dataset.orderItemId
        let url = `/order_items/${itemId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        const ingredientId = event.currentTarget.dataset.ingredientId
        const statusSent = this.element.dataset.statusSent

        const row = document.getElementById(`ingredient-row-${ingredientId}`)
        let categoryId
        if (row) {
            categoryId = row.dataset.categoryId
        }

        fetch(url, {
            method: 'DELETE',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body: JSON.stringify({
                id: itemId,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    const correspondingDiv = document.querySelector(
                        `[data-ingredient-modal-id="ingredientModalDiv_${ingredientId}"]`
                    )
                    if (correspondingDiv) {
                        correspondingDiv.classList.remove('d-none')
                    }

                    if (statusSent === 'true') {
                        const inputElement = document.querySelector(
                            `input[data-id="${itemId}"]`
                        )
                        if (inputElement) {
                            inputElement.value = 0
                            inputElement.dataset.id = ''
                        }

                        if (row) {
                            let deleteLink = row.querySelector(
                                `a[data-order-item-id="${itemId}"]`
                            )
                            if (deleteLink) {
                                deleteLink.remove()
                            }

                            const items = this.element.querySelectorAll(
                                `input[data-ingredient-id="${ingredientId}"]`
                            )
                            let totalQuantity = 0
                            let totalPrice = 0
                            items.forEach((item) => {
                                const itemQuantity = parseFloat(item.value)
                                const itemPriceCents = parseFloat(
                                    item.dataset.priceCents
                                )
                                totalQuantity += itemQuantity
                                totalPrice +=
                                    (itemPriceCents / 100) * itemQuantity
                            })

                            const orderedQuantityElement =
                                this.element.querySelector(
                                    `td[data-ordered-quantity-id="${ingredientId}"]`
                                )
                            const totalPriceElement =
                                this.element.querySelector(
                                    `td[data-total-price-id="${ingredientId}"]`
                                )

                            if (orderedQuantityElement) {
                                orderedQuantityElement.innerHTML =
                                    totalQuantity *
                                    parseFloat(items[0].dataset.quantityByPack)
                            }

                            if (totalPriceElement) {
                                totalPriceElement.innerHTML =
                                    '€' + totalPrice.toFixed(2)
                            }

                            if (items.length === 1 && items[0].value == 0) {
                                row.remove()
                            }
                        } else {
                            console.error(
                                `Row with ingredient ID ${ingredientId} not found.`
                            )
                        }
                    } else {
                        if (row) {
                            row.remove()
                        }
                        this.selectedIngredientIds.delete(ingredientId)
                    }

                    // Vérifier s'il reste d'autres lignes dans la même catégorie
                    if (categoryId) {
                        const otherRowsInCategory =
                            this.ingredientFieldsTarget.querySelectorAll(
                                `tr[data-category-id="${categoryId}"]`
                            )
                        if (otherRowsInCategory.length === 1) {
                            const categoryRow =
                                this.ingredientFieldsTarget.querySelector(
                                    `tr.table-info[data-category-id="${categoryId}"]`
                                )
                            if (categoryRow) {
                                categoryRow.remove()
                            }
                        }
                    }

                    // Mise à jour de la quantité totale et vérification de l'avertissement après la suppression
                    this.updateTotalPrice()
                    this.checkDeliveryDelays()
                } else {
                    console.error('Erreur lors de la suppression')
                }
            })
            .catch((error) => {
                console.error('Erreur:', error)
            })
    }

    updateQuantity(input) {
        if (this.isUpdating) return

        clearTimeout(this.debounceTimeout)

        const inputElement = input.currentTarget

        this.debounceTimeout = setTimeout(() => {
            this.isUpdating = true
            this._performUpdateQuantity({
                currentTarget: inputElement,
            })
        }, 300)
    }
    _performUpdateQuantity(input) {
        const inputElement = input.currentTarget
        const id = inputElement.dataset.id
        const unitMeasure = inputElement.dataset.unitMeasure
        const statusSent = this.element.dataset.statusSent
        const supplierOrderId = this.element.dataset.supplierOrderId
        let ingredientId = inputElement.dataset.ingredientId
        let quantity = parseFloat(inputElement.value)

        if (quantity < 0) {
            quantity = 0
            inputElement.value = (0).toFixed(1)
        } else if (unitMeasure === 'PC' && !Number.isInteger(quantity)) {
            quantity =
                quantity % 1 < 0.5 ? Math.floor(quantity) : Math.ceil(quantity)
            inputElement.value = quantity.toFixed(0)
        }

        let url
        let method
        if (id === '') {
            url = `/supplier_orders/${supplierOrderId}/order_items`
            method = 'POST'
        } else {
            url = `/order_items/${id}`
            method = 'PATCH'
        }

        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }

        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body: JSON.stringify({
                quantity: quantity,
                status_sent: statusSent,
                ingredient_id: ingredientId,
                supplier_order_id: supplierOrderId,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (id === '') {
                    inputElement.dataset.id = data.order_item.id
                    inputElement.dataset.priceCents =
                        data.order_item.ingredient.price_cents
                    inputElement.dataset.quantityByPack =
                        data.order_item.ingredient.quantity_by_pack

                    let cell8 = document.getElementById(
                        `ingredient-row-${data.order_item.ingredient_id}`
                    ).cells[8]
                    let deleteLink = document.createElement('a')
                    deleteLink.innerHTML = '<i class="fa-solid fa-trash"></i>'
                    deleteLink.href = '#'
                    deleteLink.dataset.action =
                        'click->modal-ingredients#delete'
                    deleteLink.dataset.orderItemId = data.order_item.id
                    deleteLink.dataset.ingredientId =
                        data.order_item.ingredient_id
                    cell8.appendChild(deleteLink)
                    cell8.setAttribute('scope', 'row')
                    cell8.setAttribute('style', 'width:7%')
                    cell8.classList.add('text-center', 'align-middle')
                }

                // Supprimer la corbeille si la quantité passe à zéro et que addedAfterSend est true
                if (
                    inputElement.dataset.addedAfterSend === 'true' &&
                    quantity === 0
                ) {
                    let cell8 = document.getElementById(
                        `ingredient-row-${ingredientId}`
                    ).cells[8]
                    if (cell8) {
                        let deleteLink = cell8.querySelector('a')
                        if (deleteLink) {
                            deleteLink.remove()
                        }
                    }
                }

                // Ajouter la corbeille si la quantité passe de 0 à une valeur positive et que addedAfterSend est true
                if (
                    inputElement.dataset.addedAfterSend === 'true' &&
                    quantity > 0
                ) {
                    let cell8 = document.getElementById(
                        `ingredient-row-${ingredientId}`
                    ).cells[8]
                    if (cell8 && !cell8.querySelector('a')) {
                        let deleteLink = document.createElement('a')
                        deleteLink.innerHTML =
                            '<i class="fa-solid fa-trash"></i>'
                        deleteLink.href = '#'
                        deleteLink.dataset.action =
                            'click->modal-ingredients#delete'
                        deleteLink.dataset.orderItemId = id
                        deleteLink.dataset.ingredientId = ingredientId
                        cell8.appendChild(deleteLink)
                    }
                }

                this.updateQuantityDisplay(data.order_item.ingredient_id)
                this.checkDeliveryDelays()

                // Vérification finale pour s'assurer que la quantité enregistrée correspond à la valeur dans l'input
                setTimeout(() => {
                    const currentQuantity = parseFloat(inputElement.value)
                    if (currentQuantity !== data.order_item.quantity) {
                        console.log(
                            'Réajustement nécessaire, quantité incorrecte détectée.'
                        )
                        this._correctQuantityIfNeeded(
                            inputElement,
                            data.order_item.id
                        )
                    }
                }, 500) // Attendre un court délai avant de vérifier
            })
            .catch((error) => {
                console.error('Error:', error)
            })
            .finally(() => {
                this.isUpdating = false
            })
    }

    _correctQuantityIfNeeded(inputElement, orderItemId) {
        const supplierOrderId = this.element.dataset.supplierOrderId
        const quantity = parseFloat(inputElement.value)
        const statusSent = this.element.dataset.statusSent

        let url = `/order_items/${orderItemId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }

        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body: JSON.stringify({
                quantity: quantity,
                status_sent: statusSent,
                supplier_order_id: supplierOrderId,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(
                    'Quantité corrigée sur le serveur:',
                    data.order_item.quantity
                )
                this.updateQuantityDisplay(data.order_item.ingredient_id)
            })
            .catch((error) => {
                console.error(
                    'Erreur lors de la correction de la quantité:',
                    error
                )
            })
    }

    updateQuantityDisplay(ingredientId) {
        const items = this.element.querySelectorAll(
            `input[data-ingredient-id="${ingredientId}"]`
        )
        let totalQuantity = 0
        let totalPrice = 0
        items.forEach((item) => {
            const itemQuantity = parseFloat(item.value)
            const itemPriceCents = parseFloat(item.dataset.priceCents)
            totalQuantity += itemQuantity
            totalPrice += (itemPriceCents / 100) * itemQuantity
        })

        this.element.querySelector(
            `td[data-ordered-quantity-id="${ingredientId}"]`
        ).innerHTML =
            totalQuantity * parseFloat(items[0].dataset.quantityByPack)
        this.element.querySelector(
            `td[data-total-price-id="${ingredientId}"]`
        ).innerHTML = '€' + totalPrice.toFixed(2)

        this.updateTotalPrice()
    }

    updateTotalPrice() {
        const franco = parseInt(this.francoSubmitTarget.dataset.francoAmount)
        const maximumOrderValue = parseFloat(
            this.francoSubmitTarget.dataset.maximumOrderValue
        )
        let totalOrder = 0
        this.totalRowPriceTargets.forEach((row) => {
            totalOrder += parseFloat(row.textContent.replace('€', '').trim())
        })
        this.totalOrderPriceTarget.innerHTML = `Franco: €${totalOrder.toFixed(2)} / €${franco.toFixed(2)}`
        if (totalOrder >= franco) {
            this.totalOrderPriceTarget.classList.add('btn-success')
            this.totalOrderPriceTarget.classList.remove('btn-danger')
        } else if (totalOrder < franco) {
            this.totalOrderPriceTarget.classList.remove('btn-success')
            this.totalOrderPriceTarget.classList.add('btn-danger')
        }

        if (maximumOrderValue > 0 && totalOrder > maximumOrderValue) {
            this.maximumValueWarningTarget.classList.remove('d-none')
            this.totalOrderPriceTarget.classList.add('btn-danger')
        } else {
            this.maximumValueWarningTarget.classList.add('d-none')
        }
        this.updateSenderOrderButton(totalOrder, franco, maximumOrderValue)
    }

    updateSenderOrderButton(totalOrder, franco, maximumOrderValue) {
        const sendOrderButton = document.querySelector('.send-order')
        const stillEditable = this.element.dataset.stillEditable === 'true'

        let isQuantityZero = false
        let oneQtyAfterSendWithNoZero = false
        let elements = document.querySelectorAll('.ingredient-quantity')
        const isSent = this.element.dataset.statusSent === 'true'

        // Regrouper les éléments par ingredient_id
        let ingredientsMap = new Map()
        elements.forEach((element) => {
            const ingredientId = element.dataset.ingredientId
            if (!ingredientsMap.has(ingredientId)) {
                ingredientsMap.set(ingredientId, [])
            }
            ingredientsMap.get(ingredientId).push(element)
        })

        // Parcourir chaque groupe.
        ingredientsMap.forEach((ingredientElements, ingredientId) => {
            let hasItemNotAddedAfterSend = false
            let hasItemAddedAfterSendWithZero = false
            let hasItemNotAddedAfterSendwithZero = false

            ingredientElements.forEach((element) => {
                const input_value = parseInt(element.value, 10)
                const addedAfterSend = element.dataset.addedAfterSend === 'true'

                if (!addedAfterSend && input_value > 0) {
                    hasItemNotAddedAfterSend = true
                } else if (addedAfterSend && input_value === 0) {
                    hasItemAddedAfterSendWithZero = true
                } else if (!addedAfterSend && input_value === 0) {
                    hasItemNotAddedAfterSendwithZero = true
                } else if (addedAfterSend && input_value > 0) {
                    oneQtyAfterSendWithNoZero = true
                }
            })

            if (
                (!hasItemNotAddedAfterSend && hasItemAddedAfterSendWithZero) ||
                hasItemNotAddedAfterSendwithZero
            ) {
                isQuantityZero = true
            }
        })

        // Reset initial state and check conditions
        if (
            !stillEditable ||
            (!isSent && (isQuantityZero || franco > totalOrder)) ||
            (maximumOrderValue > 0 && totalOrder > maximumOrderValue) ||
            (isSent && !oneQtyAfterSendWithNoZero)
        ) {
            sendOrderButton.classList.add('disabled')
            sendOrderButton.setAttribute('disabled', 'disabled')
        } else {
            sendOrderButton.classList.remove('disabled')
            sendOrderButton.removeAttribute('disabled')
        }

        // Gestion des avertissements
        this.zeroValuesWarningTarget.classList.toggle(
            'd-none',
            !(isQuantityZero && !isSent)
        )
        this.francoReachingWarningTarget.classList.toggle(
            'd-none',
            !(totalOrder < franco && !isSent)
        )
        if (maximumOrderValue > 0) {
            this.maximumValueWarningTarget.classList.toggle(
                'd-none',
                !(totalOrder > maximumOrderValue)
            )
        }
    }
}
