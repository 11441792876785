import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)

const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

export default class extends Controller {
    static targets = ['time']

    connect() {
        this.setDefaultTime()
        this.loadChart()
    }

    setDefaultTime() {
        const selectElement = this.element.querySelector('select')
        if (selectElement && selectElement.options.length > 0) {
            selectElement.options[0].selected = true
        }
    }

    updateChart() {
        const selectedPeriod = this.timeTarget.value
        this.chart.showLoading()
        let url = `/dashboard/call_pie_margin_by_category_service?period=${selectedPeriod}&format=json`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }

        fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then((receivedData) => {
                const chartData = this.element.querySelector(
                    '#chart-data-dashboard-pie-margin'
                )
                const nbProductsToShow = JSON.parse(
                    chartData.getAttribute('data-nb-max-product-to-show')
                )

                receivedData.sort((a, b) => parseFloat(b.y) - parseFloat(a.y))
                const topData = receivedData
                    .slice(0, nbProductsToShow)
                    .map((item) => ({
                        name: item.name,
                        y: parseFloat(item.y),
                        visible: true,
                        drilldown: item.drilldown,
                    }))

                const othersTotal = receivedData
                    .slice(nbProductsToShow)
                    .reduce((acc, item) => acc + parseFloat(item.y), 0)
                const total = receivedData.reduce(
                    (acc, item) => acc + parseFloat(item.y),
                    0
                )

                const negativeCategories = receivedData
                    .filter((item) => parseFloat(item.y) < 0) // Assurez-vous que item.y est bien un nombre
                    .map((item) => {
                        const formattedValue = parseFloat(
                            item.y
                        ).toLocaleString('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                            minimumFractionDigits: 0,
                        })
                        return `<span style="color:red;">&#9888; ${item.name}: ${formattedValue}</span>`
                    })
                    .join('<br>')

                this.chart.update({
                    series: [
                        {
                            name: 'Margin',
                            data: topData,
                        },
                    ],
                    subtitle: {
                        text:
                            `<br>${i18n.t('i18njs.Total Margin')}: ` +
                            total.toLocaleString('fr-FR', {
                                style: 'currency',
                                currency: 'EUR',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }) +
                            (negativeCategories
                                ? `<br><br><strong>${i18n.t('i18njs.Negative categories')}:</strong><br>` +
                                  negativeCategories
                                : ''),
                    },
                    drilldown: {
                        series: topData
                            .filter((item) => item.drilldown)
                            .map((item) => ({
                                id: item.name,
                                data: item.drilldown.map((sub) => [
                                    sub.name,
                                    sub.y,
                                ]),
                            })),
                    },
                })
                this.chart.hideLoading()
            })
            .catch((error) => {
                console.error('Error updating chart:', error)
            })
    }

    loadChart() {
        const chartData = this.element.querySelector(
            '#chart-data-dashboard-pie-margin'
        )
        if (!chartData) {
          console.error("Chart container not found!");
          return;
       }
        const rawData = JSON.parse(
            chartData.getAttribute('data-margin-by-category-yesterday')
        )
        const nbProductsToShow = JSON.parse(
            chartData.getAttribute('data-nb-max-product-to-show')
        )
        rawData.sort((a, b) => parseFloat(b.y) - parseFloat(a.y))
        const total = rawData.reduce((acc, item) => acc + parseFloat(item.y), 0)
        const topData = rawData.slice(0, nbProductsToShow).map((item) => ({
            name: item.name,
            y: parseFloat(item.y),
            visible: true,
            drilldown: item.drilldown,
        }))
        const negativeCategories = rawData
            .filter((item) => parseFloat(item.y) < 0)
            .map((item) => {
                const formattedValue = parseFloat(item.y).toLocaleString(
                    'fr-FR',
                    {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 0,
                    }
                )
                return `<span style="color:red;">&#9888; ${item.name}: ${formattedValue}</span>`
            })
            .join('<br>')
        this.chart = Highcharts.chart('chart-data-dashboard-pie-margin', {
            chart: {
                type: 'pie',
                style: {
                    width: '100%',
                },
            },
            title: {
                text: null,
            },
            subtitle: {
                text:
                    `<br>${i18n.t('i18njs.Total Margin')}: ` +
                    total.toLocaleString('fr-FR', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    }) +
                    (negativeCategories
                        ? `<br><br><strong>${i18n.t('i18njs.Negative categories')}:</strong><br>` +
                          negativeCategories
                        : ''),
                align: 'right',
                verticalAlign: 'bottom',
                y: 5,
                style: {
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: 'bold',
                },
            },
            plotOptions: {
                pie: {
                    size: '75%',
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y:.0f}€',
                        style: {
                            fontSize: '10px',
                            color:
                                (Highcharts.theme &&
                                    Highcharts.theme.contrastTextColor) ||
                                'black',
                            textOutline: 'none',
                            fontWeight: 'normal',
                        },
                        distance: 15,
                    },
                    showInLegend: true,
                },
            },
            series: [
                {
                    name: i18n.t('i18njs.Margin'),
                    data: topData,
                },
            ],
            drilldown: {
                series: topData
                    .filter((item) => item.drilldown)
                    .map((item) => ({
                        id: item.name,
                        data: item.drilldown.map((sub) => [sub.name, sub.y]),
                    })),
            },
            tooltip: {
                useHTML: true,
                headerFormat: '',
                pointFormatter: function () {
                    const totalMargin = Highcharts.numberFormat(
                        this.y,
                        0,
                        ',',
                        '.'
                    ) // Affiche correctement le signe négatif
                    let tooltipHtml = `<span style="font-size:14px">${this.name}: <b>${totalMargin}€</b></span><br/><table class="table table-striped table-bordered table-hover table-sm">`
                    tooltipHtml += `<thead><tr><th>${i18n.t('i18njs.Sub categories')}</th><th>€</th><th>%</th></tr></thead><tbody>`

                    if (this.drilldown) {
                        this.drilldown.forEach((sub) => {
                            const subMargin = Highcharts.numberFormat(
                                sub.y,
                                0,
                                ',',
                                '.'
                            )
                            const subPercentage = (
                                (sub.y / this.y) *
                                100
                            ).toFixed(1)
                            tooltipHtml += `<tr><td>${sub.name}</td><td>${subMargin}€</td><td>${subPercentage}%</td></tr>`
                        })
                    }

                    tooltipHtml += `</tbody></table>`
                    return tooltipHtml
                },
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        })
    }
}
