import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [
        'bonusOnTurnoverCell',
        'totalBonus',
        'caRow',
        'objectiveRow',
        'fixedBonusRow',
        'variableBonusRow',
        'daysRow',
        'lossesRow',
        'fixedLossesBonusRow',
        'objectiveLossesRow',
        'totalLossesBonus',
        'bonusOnLossesCell',
        'percentStudentRow',
        'fixedPercentStudentBonusRow',
        'objectivePercentStudentRow',
        'totalPercentStudentBonus',
        'bonusOnPercentStudentCell',
        'productivityRow',
        'fixedProductivityBonusRow',
        'objectiveProductivityRow',
        'totalProductivityBonus',
        'bonusOnProductivityCell',
        'grandTotalBonus'
    ]

    connect() {
      if (this.hasInputTarget) {
          this.calculateGrowth({ target: this.inputTarget })
      }
  }

    observeChanges() {
        // Observer tous les inputs sur les lignes concernées
        const targets = [
            'objectiveRow',
            'fixedBonusRow',
            'variableBonusRow',
            'fixedLossesBonusRow',
            'objectiveLossesRow',
            'fixedPercentStudentBonusRow',
            'objectivePercentStudentRow',
            'fixedProductivityBonusRow',
            'objectiveProductivityRow',

        ]
        targets.forEach((targetName) => {
            const row = this[`${targetName}Target`]
            if (row) {
                // Ajouter les événements à chaque input
                row.querySelectorAll('input').forEach((input) => {
                    ;['change', 'input'].forEach((eventType) => {
                        input.addEventListener(eventType, () => {
                            // Forcer un recalcul complet à chaque changement
                            requestAnimationFrame(() => {
                                this.calculateAllBonus()
                            })
                        })
                    })
                })
            }
        })

        // Déclencher un calcul initial pour chaque input
        targets.forEach((targetName) => {
            const row = this[`${targetName}Target`]
            if (row) {
                row.querySelectorAll('input').forEach((input) => {
                    if (input.value) {
                        // Déclencher un événement pour forcer le calcul initial
                        input.dispatchEvent(new Event('change'))
                    }
                })
            }
        })
    }

    calculateAllBonus() {
      let monthlyTotals = {}
      let grandTotal = 0
      let turnoverTotal = 0;
      let lossesTotal = 0;
      let productivityTotal = 0;
      let percentStudentTotal = 0;
      const currentDate = new Date()
      const currentMonth = currentDate.getMonth() + 1

      // Calculer les bonus turnover par mois
      this.bonusOnTurnoverCellTargets.forEach((cell) => {
          const month = parseInt(cell.dataset.month)
          const bonus = this.calculateMonthBonusTurnover(month, currentMonth)
          cell.textContent = bonus > 0 ? this.formatMoney(bonus) : '0 €'
          monthlyTotals[month] = (monthlyTotals[month] || 0) + bonus
          turnoverTotal += bonus
          grandTotal += bonus
      })

      // Calculer les bonus losses par mois
      this.bonusOnLossesCellTargets.forEach((cell) => {
          const month = parseInt(cell.dataset.month)
          const bonus = this.calculateMonthBonusLosses(month, currentMonth)
          cell.textContent = bonus > 0 ? this.formatMoney(bonus) : '0 €'
          monthlyTotals[month] = (monthlyTotals[month] || 0) + bonus
          lossesTotal += bonus
          grandTotal += bonus
      })

      this.bonusOnPercentStudentCellTargets.forEach((cell) => {
          const month = parseInt(cell.dataset.month)
          const bonus = this.calculateMonthBonusPercentStudent(month, currentMonth)
          cell.textContent = bonus > 0 ? this.formatMoney(bonus) : '0 €'
          monthlyTotals[month] = (monthlyTotals[month] || 0) + bonus
          percentStudentTotal += bonus
          grandTotal += bonus
      })

      this.bonusOnProductivityCellTargets.forEach((cell) => {
          const month = parseInt(cell.dataset.month)
          const bonus = this.calculateMonthBonusProductivity(month, currentMonth)
          cell.textContent = bonus > 0 ? this.formatMoney(bonus) : '0 €'
          monthlyTotals[month] = (monthlyTotals[month] || 0) + bonus
          productivityTotal += bonus
          grandTotal += bonus
      })

      if (this.hasTotalBonusTarget) {
          this.totalBonusTarget.textContent = turnoverTotal > 0 ? this.formatMoney(turnoverTotal) : '0 €'
      }

      if (this.hasTotalLossesBonusTarget) {
          this.totalLossesBonusTarget.textContent = lossesTotal > 0 ? this.formatMoney(lossesTotal) : '0 €'
      }

      if (this.hasTotalPercentStudentBonusTarget) {
          this.totalPercentStudentBonusTarget.textContent = percentStudentTotal > 0 ? this.formatMoney(percentStudentTotal) : '0 €'
      }

      if (this.hasTotalProductivityBonusTarget) {
          this.totalProductivityBonusTarget.textContent = productivityTotal > 0 ? this.formatMoney(productivityTotal) : '0 €'
      }

      // Afficher les totaux mensuels et le grand total
      this.grandTotalBonusTargets.forEach((cell) => {
          const month = parseInt(cell.dataset.month)
          if (month) {
              cell.textContent = monthlyTotals[month] > 0 ? this.formatMoney(monthlyTotals[month]) : '0 €'
          } else {
              cell.textContent = grandTotal > 0 ? this.formatMoney(grandTotal) : '0 €'
          }
      })
  }

    calculateMonthBonusLosses(month, currentMonth) {
        try {
            if (
                !this.hasLossesRowTarget ||
                !this.hasObjectiveLossesRowTarget ||
                !this.hasFixedLossesBonusRowTarget
            ) {
                return 0
            }
            const lossesCell = this.lossesRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )
            const objectiveCell = this.objectiveLossesRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )
            const fixedBonusCell = this.fixedLossesBonusRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )

            const lossesText =
                lossesCell?.querySelector('.text-danger')?.textContent || ''
            const realized =
                parseFloat(lossesText.replace('%', '').replace(',', '.')) || 0
            const objective = this.extractInputValue(objectiveCell)
            const fixedBonus = this.extractInputValue(fixedBonusCell)

            if (!realized || !objective || !fixedBonus) return 0

            if (realized <= objective) {
                return fixedBonus || 0
            }

            return 0
        } catch (e) {
            console.warn(`Error in calculateMonthBonus for month ${month}:`, e)
            return 0
        }
    }

    calculateMonthBonusProductivity(month, currentMonth) {
        try {
            if (
                !this.hasProductivityRowTarget ||
                !this.hasObjectiveProductivityRowTarget ||
                !this.hasFixedProductivityBonusRowTarget
            ) {
                return 0
            }
            const productivityCell = this.productivityRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )
            const objectiveCell = this.objectiveProductivityRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )
            const fixedBonusCell = this.fixedProductivityBonusRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )
            const realized = this.extractProductivityValue(productivityCell)
            const objective = this.extractInputValue(objectiveCell)
            const fixedBonus = this.extractInputValue(fixedBonusCell)

            if (!realized || !objective || !fixedBonus) return 0

            if (realized >= objective) {
                return fixedBonus || 0
            }

            return 0
        } catch (e) {
            console.warn(`Error in calculateMonthBonus for month ${month}:`, e)
            return 0
        }
    }

    calculateMonthBonusPercentStudent(month, currentMonth) {
        try {
            if (
                !this.hasPercentStudentRowTarget ||
                !this.hasObjectivePercentStudentRowTarget ||
                !this.hasFixedPercentStudentBonusRowTarget
            ) {
                return 0
            }
            const percentStudentCell = this.percentStudentRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )
            const objectiveCell = this.objectivePercentStudentRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )
            const fixedBonusCell = this.fixedPercentStudentBonusRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )
            const percentStudentText = percentStudentCell?.textContent.trim() || ''
            const realized =
                parseFloat(percentStudentText.replace('%', '').replace(',', '.')) || 0
            const objective = this.extractInputValue(objectiveCell)
            const fixedBonus = this.extractInputValue(fixedBonusCell)

            if (!realized || !objective || !fixedBonus) return 0

            if (realized >= objective) {
                return fixedBonus || 0
            }

            return 0
        } catch (e) {
            console.warn(`Error in calculateMonthBonus for month ${month}:`, e)
            return 0
        }
    }

    calculateMonthBonusTurnover(month, currentMonth) {
        try {
            const caCell = this.caRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )
            const objectiveCell = this.objectiveRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )
            const fixedBonusCell = this.fixedBonusRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )
            const variableBonusCell = this.variableBonusRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )
            const daysCell = this.daysRowTarget.querySelector(
                `td:nth-child(${month + 1})`
            )

            const realizedCA = this.extractCAValue(caCell)
            const objectiveCA = this.extractInputValue(objectiveCell)
            const fixedBonus = this.extractInputValue(fixedBonusCell)
            const variableBonus =
                this.extractInputValue(variableBonusCell) / 100

            if (!realizedCA || !objectiveCA || (!fixedBonus && !variableBonus))
                return 0

            const daysText = daysCell?.textContent.trim() || '0'
            let realizedDays = 0
            let theoreticalDays = 0

            // Détecter si le texte contient un "/"
            if (daysText.includes('/')) {
                ;[realizedDays, theoreticalDays] = daysText
                    .split('/')
                    .map((d) => parseInt(d) || 0)
            } else {
                // Si pas de "/", c'est uniquement les jours théoriques
                theoreticalDays = parseInt(daysText) || 0
            }

            let targetCA = objectiveCA
            if (month === currentMonth) {
                const currentDay = new Date().getDate()
                if (theoreticalDays > 0) {
                    targetCA = (objectiveCA / theoreticalDays) * realizedDays
                } else {
                    console.warn(
                        `Cannot calculate targetCA for month ${currentMonth}, theoreticalDays is 0.`
                    )
                }
            }

            // Utilisez `theoreticalDays` pour les autres calculs
            if (theoreticalDays === 0) {
                console.warn(
                    `Skipping bonus calculation for month ${month}, theoreticalDays is 0.`
                )
                return 0
            }

            if (realizedCA > targetCA) {
                const fixedBonusAmount = fixedBonus || 0

                const variableBonusAmount =
                    (realizedCA - targetCA) * (variableBonus || 0)
                return fixedBonusAmount + variableBonusAmount
            }

            return 0
        } catch (e) {
            console.warn(`Error in calculateMonthBonus for month ${month}:`, e)
            return 0
        }
    }

    extractCAValue(cell) {
        if (!cell) return 0
        return parseFloat(cell.dataset.realizedCa) || 0
    }

    extractInputValue(cell) {
        if (!cell) return 0
        const input = cell.querySelector('input')
        return input ? parseFloat(input.value) || 0 : 0
    }

    formatMoney(amount) {
        return new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0,
        }).format(amount)
    }

    extractProductivityValue(cell) {
      if (!cell) return 0
      const cellText = cell.textContent.trim()
      return parseFloat(cellText.replace('€', '').replace('.', '').replace(',', '.')) || 0
  }

}
