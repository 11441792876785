import { Controller } from "@hotwired/stimulus"
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ["container", "startDate", "endDate"]

  connect() {
    flatpickr(this.startDateTarget, {
      dateFormat: "d-m-Y",
      minDate: "today",
      locale: { firstDayOfWeek: 1 },
      position: "above"
    })

    flatpickr(this.endDateTarget, {
      dateFormat: "d-m-Y",
      minDate: "today",
      locale: { firstDayOfWeek: 1 },
      position: "above"
    })
  }

  add(event) {
    event.preventDefault()
    const startDate = this.startDateTarget.value
    const endDate = this.endDateTarget.value || startDate

    if (!this.validateDates(startDate, endDate)) return

    let baseUrl = '/supplier_closures'
    if (document.body.dataset.locale) {
      baseUrl = `/${document.body.dataset.locale}${baseUrl}`
    }

    fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        supplier_closure: {
          supplier_id: this.element.dataset.supplierId,
          start_date: startDate,
          end_date: endDate
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      if(data.success) {
        this.containerTarget.insertAdjacentHTML('beforeend', this.closureHtml(data.closure))
        this.resetForm()
      }
    })
  }


  closureHtml(closure) {
    const endDateHtml = closure.start_date !== closure.end_date ?
      ` - ${closure.end_date}` : ''

    return `
      <div class="closure-date-item mb-2">
        <div class="alert alert-info d-flex justify-content-between align-items-center">
          <span>${closure.start_date}${endDateHtml}</span>
          <a href="#" class="text-danger" data-action="click->closure-dates#remove" data-closure-id="${closure.id}">
            <i class="fa-solid fa-times"></i>
          </a>
        </div>
      </div>
    `
  }

  remove(event) {
    event.preventDefault()
    const closureId = event.currentTarget.dataset.closureId

    let baseUrl = `/supplier_closures/${closureId}`
    if (document.body.dataset.locale) {
      baseUrl = `/${document.body.dataset.locale}${baseUrl}`
    }

    fetch(baseUrl, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then(() => {
      event.target.closest('.closure-date-item').remove()
    })
  }

  validateDates(startDate, endDate) {
    if (!startDate) {
      alert('Please select start date')
      return false
    }

    if (endDate && new Date(endDate) < new Date(startDate)) {
      alert('End date must be after start date')
      return false
    }

    return true
  }

  resetForm() {
    this.startDateTarget._flatpickr.clear()
    this.endDateTarget._flatpickr.clear()
  }
}
