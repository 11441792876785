window.reinitializeModal = function() {
  const modalElement = document.getElementById('passwordModal');
  if (modalElement) {
    // Nettoyer complètement les éléments existants
    const oldBackdrops = document.querySelectorAll('.modal-backdrop');
    oldBackdrops.forEach(backdrop => backdrop.remove());

    // S'assurer que le body n'a pas les classes modal
    document.body.classList.remove('modal-open');
    document.body.style.removeProperty('padding-right');

    // Initialiser le nouveau modal
    const modal = new bootstrap.Modal(modalElement);

    // Gérer la fermeture proprement
    modalElement.addEventListener('hidden.bs.modal', function () {
      const backdrops = document.querySelectorAll('.modal-backdrop');
      backdrops.forEach(backdrop => backdrop.remove());
      document.body.classList.remove('modal-open');
      document.body.style.removeProperty('padding-right');
    });

    modal.show();
  }
}
