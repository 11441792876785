import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [
        'input',
        'fixedBonusInput',
        'variableBonusInput',
        'growth',
        'turnoverByDay',
        'lossesFixedBonusInput',
        'lossesObjectiveInput',
        'percentStudentFixedBonusInput',
        'percentStudentObjectiveInput',
        'productivityFixedBonusInput',
        'productivityObjectiveInput',
    ]

    async saveObjective(event) {
        event.preventDefault()
        const input = event.target

        const value = parseFloat(input.value) || 0
        if (value === 0) {
            input.classList.add('is-invalid')
            setTimeout(() => input.classList.remove('is-invalid'), 2000)
            return
        }

        const targetType = input.dataset.objectiveTarget

        // Trouver les autres inputs liés au même mois/année
        const row = input.closest('tr')
        const allRows = row.parentElement.querySelectorAll(
            'tr[data-controller="objective"]'
        )

        let objectiveInput, fixedBonusInput, variableBonusInput, lossesFixedBonusInput, lossesObjectiveInput,
        percentStudentFixedBonusInput, percentStudentObjectiveInput, productivityFixedBonusInput, productivityObjectiveInput

        allRows.forEach((tr) => {
            const inputs = tr.querySelectorAll('input')
            inputs.forEach((inp) => {
                if (inp.dataset.month === input.dataset.month) {
                    switch (inp.dataset.objectiveTarget) {
                        case 'input':
                            objectiveInput = inp
                            break
                        case 'fixedBonusInput':
                            fixedBonusInput = inp
                            break
                        case 'variableBonusInput':
                            variableBonusInput = inp
                            break
                        case 'lossesFixedBonusInput':
                            lossesFixedBonusInput = inp
                            break
                        case 'lossesObjectiveInput':
                            lossesObjectiveInput = inp
                            break
                        case 'percentStudentFixedBonusInput':
                            percentStudentFixedBonusInput = inp
                            break
                        case 'percentStudentObjectiveInput':
                            percentStudentObjectiveInput = inp
                            break
                        case 'productivityFixedBonusInput':
                            productivityFixedBonusInput = inp
                            break
                        case 'productivityObjectiveInput':
                            productivityObjectiveInput = inp
                            break
                    }
                }
            })
        })

        const data = {
            restaurant_objective: {
                restaurant_id: input.dataset.restaurantId,
                year: input.dataset.year,
                month: input.dataset.month,
                turnover_objective: objectiveInput?.value || null,
                turnover_fixed_bonus: fixedBonusInput?.value || null,
                turnover_variable_bonus_percentage:
                    variableBonusInput?.value || null,
                losses_fixed_bonus: lossesFixedBonusInput?.value || null,
                losses_objective: lossesObjectiveInput?.value || null,
                percent_student_fixed_bonus: percentStudentFixedBonusInput?.value || null,
                percent_student_objective: percentStudentObjectiveInput?.value || null,
                productivity_fixed_bonus: productivityFixedBonusInput?.value || null,
                productivity_objective: productivityObjectiveInput?.value || null,
                day_filter: document.querySelector(
                    'input[name="day_filter"]:checked'
                )?.value,
            },
        }

        // Vérifier si un objectif existe déjà pour ce mois
        const existingValue = objectiveInput?.dataset.existingValue === 'true'

        let baseUrl = '/restaurant_objectives'
        if (document.body.dataset.locale) {
            baseUrl = `/${document.body.dataset.locale}${baseUrl}`
        }

        // Si c'est un update, ajouter l'ID dans l'URL
        if (existingValue) {
            baseUrl = `${baseUrl}/${data.restaurant_objective.restaurant_id}`
        }
        try {
            const response = await fetch(baseUrl, {
                method: existingValue ? 'PATCH' : 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': document.querySelector(
                        'meta[name="csrf-token"]'
                    ).content,
                },
                body: JSON.stringify(data),
            })

            if (!response.ok) {
                throw new Error('Network response was not ok')
            }

            const result = await response.json()

            if (result.success) {
                input.classList.add('is-valid')
                setTimeout(() => input.classList.remove('is-valid'), 2000)
                if (!existingValue) {
                    objectiveInput.dataset.existingValue = 'true'
                }
                // Déclencher le recalcul du bonus
                const tbody = input.closest('tbody')
                const bonusCalculationController =
                    this.application.getControllerForElementAndIdentifier(
                        tbody,
                        'bonus-calculation'
                    )
                if (bonusCalculationController) {
                    bonusCalculationController.calculateAllBonus()
                }
            } else {
                input.classList.add('is-invalid')
                setTimeout(() => input.classList.remove('is-invalid'), 2000)
                console.error('Erreur lors de la sauvegarde:', result.errors)
            }
        } catch (error) {
            console.error('Erreur:', error)
            input.classList.add('is-invalid')
            setTimeout(() => input.classList.remove('is-invalid'), 2000)
        }
    }

    calculateGrowth(event) {
        const currentInput = event.target
        const inputIndex = this.inputTargets.indexOf(currentInput)
        const growthDiv = this.growthTargets[inputIndex]
        const turnoverByDayDiv = this.turnoverByDayTargets[inputIndex]
        const tbody = currentInput.closest('tbody')
        const currentValue = parseFloat(currentInput.dataset.currentValue) || 0
        const objectiveValue = parseFloat(currentInput.value) || 0
        const month = parseInt(currentInput.dataset.month)

        // Trouver la ligne des jours comptés
        const daysRow = tbody.querySelector(
            '[data-bonus-calculation-target="daysRow"]'
        )
        if (!daysRow) return

        // Trouver la cellule du mois correspondant
        const daysCells = Array.from(daysRow.querySelectorAll('td'))
        const daysCell = daysCells[month]
        if (!daysCell) return

        // Extraire les jours réalisés et théoriques
        const daysText = daysCell.textContent.trim();
        const isNextYear = daysCell.dataset.nextYear === "true";

        let realizedDays = 0;
        let theoreticalDays = 0;

        if (isNextYear) {
            // Si c'est `next_year`, utilisez uniquement les jours théoriques
            theoreticalDays = parseInt(daysText) || 0;
        } else {
            // Sinon, séparez en "réalisés/théoriques"
            [realizedDays, theoreticalDays] = daysText.split('/').map((d) => parseInt(d) || 0);
        }

        if (objectiveValue > 0) {
            // Pour l'affichage du CA/jour, toujours utiliser les jours théoriques
            if (theoreticalDays > 0) {
                const turnoverByDayValue = (
                    objectiveValue / theoreticalDays
                ).toFixed(0)
                turnoverByDayDiv.innerHTML = `<div class="text-muted">${turnoverByDayValue.replace('.', ',')} €/j</div>`
            }

            // Calcul et affichage de la croissance
            if (currentValue > 0) {
                const growth = (
                    ((objectiveValue - currentValue) / currentValue) *
                    100
                ).toFixed(1)
                const isPositive = parseFloat(growth) > 0
                growthDiv.innerHTML = `<div class="${isPositive ? 'text-success' : 'text-danger'}">${growth.replace('.', ',')}%</div>`
            } else {
                growthDiv.innerHTML = ''
            }
        } else {
            growthDiv.innerHTML = ''
            turnoverByDayDiv.innerHTML = ''
        }

        // Notifier le bonus-calculation du changement
        const bonusCalculationController =
            this.application.getControllerForElementAndIdentifier(
                tbody,
                'bonus-calculation'
            )
        if (bonusCalculationController) {
            bonusCalculationController.calculateAllBonus()
        }
    }

    connect() {
        if (this.hasInputTarget) {
            this.calculateGrowth({ target: this.inputTarget })
        }
    }
}
